import {Typography, Tooltip} from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMemo } from 'react';
dayjs.extend(relativeTime);

const RunDate = ({timeStampDate, pingForUpdate}) => {
  const dateInMillis = timeStampDate.seconds * 1000;
  const nowInMillis = Date.now()
  const runDate = new Date(dateInMillis > nowInMillis ? nowInMillis : dateInMillis)
  const date = runDate.toDateString() + ' at ' + runDate.toLocaleTimeString();
  
  const relTime = useMemo(() => {
    return dayjs(runDate).from(new Date(nowInMillis));
  }, [timeStampDate, pingForUpdate]);

  // const relTime = dayjs(runDate).from(new Date(nowInMillis));
  return (
    <Tooltip title={date}>
      <Typography variant='caption' style={{color: '#bbb'}}>{relTime}</Typography>
    </Tooltip>
  );
};
export default RunDate;
