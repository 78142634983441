import React, { useState, useEffect, useContext, useCallback } from 'react';
import {Outlet, useParams, useNavigate, NavLink} from 'react-router-dom';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, TextField, Rating, Link, Paper, ToggleButtonGroup, ToggleButton, Button, Tooltip, FormControl, FormGroup, FormControlLabel, Checkbox, Select, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import Data from './Data';
import { AccountContext, UserContext } from './AccountFrame';
import { catchError } from 'rxjs';
import { REVIEW_STATE } from './ReviewStates';
import { UI_TYPE } from './UiTypes';

const ReviewForm = ({review, previewOnly=false, onResponseValuesChanged}) => {
  const accountCtx = useContext(AccountContext);
  const accountId = accountCtx?.account?.id
  const userCtx = useContext(UserContext);
  const user = userCtx?.user;
  const [formItems, setFormItems] = useState([]);
  const [formInputs, setFormInputs] = useState();
  const [responseValues, setResponseValues] = useState({});
  const [submitTrigger, setSubmitTrigger] = useState(false);
  const [allRequiredFieldsResponded, setAllRequiredFieldsResponded] = useState(false);
  const alreadyResponded = review && (review.state == REVIEW_STATE.RESPONDED);

  useEffect(() => {
    const allRequiredFields = (review && review.form && review.form.fields) ? Object.keys(review.form.fields).filter(fieldKey => review.form.fields[fieldKey].required) : []
    console.log("allRequiredFields ", allRequiredFields)
  
    const checkRequiredStrings = (required, values) => {
      for (let i = 0; i < required.length; i++) {
        const key = required[i];
        if (!(key in values) || values[key] === null || values[key].value === null || (Array.isArray(values[key].value) && values[key].value.length == 0) || ((typeof values[key].value === 'string') && values[key].value.length == 0)) {
          return false;
        }
      }
      return true;
    }
    setAllRequiredFieldsResponded(checkRequiredStrings(allRequiredFields, responseValues))
    
  }, [review, responseValues]);

  useEffect(() => {
    if (previewOnly || !accountId || !user || !submitTrigger || alreadyResponded) return () => { };
    const subscription = Data.getInstance().sendFormSubmit(user.id, user.email, accountId, review.docId, responseValues)
      .subscribe((x) => {
        setSubmitTrigger(false);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [submitTrigger]);
    
  useEffect(() => {
    if (onResponseValuesChanged) {
      onResponseValuesChanged(responseValues)
    }
  }, [responseValues]);
    
  useEffect(() => {
    console.log("setFormItems", review)

    const getOrderedFieldsWithValues = (review) => {
      const { form, fields } = review;
      const { fieldOrder, fields: formFields } = form;
    
      return fieldOrder.map(fieldKey => {
        const field = { ...formFields[fieldKey] };
        if (fields && fields.hasOwnProperty(fieldKey))
          field.requestValues = fields[fieldKey];
        field.field = fieldKey;
        return field;
      });
    }

    setFormItems((review && review.form && review.form.fields) ? getOrderedFieldsWithValues(review) : [])
  }, [review]);

  useEffect(() => {
    if (!formItems || !formItems.length) return () => { };
    
    const defaultFormInputs = {}
    for (let formItem of formItems) {
      if (formItem.requestValues && [UI_TYPE.TEXT, UI_TYPE.TEXT_INPUT, UI_TYPE.RATING].includes(formItem.uiType))
        defaultFormInputs[formItem.field] = formItem.requestValues
    }
    console.log("defaultFormInputs", defaultFormInputs)
    setFormInputs(prev => {return {...defaultFormInputs, ...prev}})
  }, [formItems]);
    
  useEffect(() => {
    console.log("formInputs", formInputs)
    console.log("formItems", formItems)
    if (!formInputs) return () => { };

    const getTextResponseField = (formItem, newValue) => {
      return { value: newValue || "", ...(formItem.requestValues && {wasEdited: (formItem.requestValues !== newValue)}) };
    }
  
    const getRatingResponseField = (formItem, newValue) => {
      return { value: newValue, ...(formItem.requestValues && {wasEdited: (formItem.requestValues !== newValue)}) };
    }

    const newResponseValues = {}
    for (let formItem of formItems) {
      const inputOrRequestValue = formInputs[formItem.field] || formItem.requestValues
      if (formItem.uiType === UI_TYPE.TEXT || formItem.uiType === UI_TYPE.TEXT_INPUT) {
        newResponseValues[formItem.field] = getTextResponseField(formItem, inputOrRequestValue)
      } else if (formItem.uiType === UI_TYPE.RATING) {
        if (inputOrRequestValue) {
          newResponseValues[formItem.field] = getRatingResponseField(formItem, inputOrRequestValue)
        }
      } else if (formItem.uiType === UI_TYPE.CHECKBOXES || formItem.uiType === UI_TYPE.DROPDOWN || formItem.uiType === UI_TYPE.BUTTON_SELECT) {
        if (formInputs[formItem.field]) {
          newResponseValues[formItem.field] = {value: formInputs[formItem.field]}
        }
      }
    }
    console.log("newResponseValues", newResponseValues)
    setResponseValues(newResponseValues)
  }, [formItems, formInputs]);

  const onInputChanged = useCallback((formItem, newValue) => {
    console.log("onInputChanged " + formItem.field, newValue)
    setFormInputs(current => {return {...current, [formItem.field]: newValue}})
  },[])

  const mapUiTypes = (formItem) => {
    const currentValue = alreadyResponded ? review.responseValues[formItem.field]?.value : (formInputs ? formInputs[formItem.field] : null)
    // console.log("mapUiTypes currentValue", currentValue)
    switch (formItem.uiType) {
      case UI_TYPE.STATIC_TEXT:
        return <StaticTextComponent text={formItem.value} />
      case UI_TYPE.STATIC_HEADER:
        return <HeaderComponent text={formItem.value} />
      case UI_TYPE.CHECKBOXES:
        return <Checkboxes formItem={formItem} disabled={submitTrigger || alreadyResponded} currentValue={currentValue} onChange={onInputChanged}  />
      case UI_TYPE.DROPDOWN:
        return <DropDownSelect formItem={formItem} disabled={submitTrigger || alreadyResponded} currentValue={currentValue} onChange={onInputChanged}  />
      case UI_TYPE.BUTTON_SELECT:
        return <ButtonSelect formItem={formItem} disabled={submitTrigger || alreadyResponded} currentValue={currentValue} onChange={onInputChanged} />
      case UI_TYPE.HEADER:
        return formItem.requestValues ? <HeaderComponent text={formItem.requestValues} /> : ""
      case UI_TYPE.TEXT:
      case UI_TYPE.TEXT_INPUT:
        // console.log("textCurr ", currentValue)
        return <TextComponent formItem={formItem} disabled={submitTrigger || alreadyResponded} startEditorOpen={formItem.uiType === UI_TYPE.TEXT_INPUT} currentValue={currentValue} onChange={onInputChanged} />
      case UI_TYPE.FILE_LINK:
        return formItem.requestValues ? <FileLink values={formItem.requestValues} /> : ""
      case UI_TYPE.URL_LINK:
        return formItem.requestValues ? <UrlLink values={formItem.requestValues} /> : ""
      case UI_TYPE.CARD_GRID:
        return formItem.requestValues ? <CardGrid values={formItem.requestValues} /> : ""
      case UI_TYPE.IMAGE_GRID:
        return formItem.requestValues ? <ImageGrid values={formItem.requestValues} /> : ""
      case UI_TYPE.RATING:
        console.log("ratingCurr ", currentValue)
        return <RatingBar formItem={formItem} disabled={submitTrigger || alreadyResponded} currentValue={currentValue} onChange={onInputChanged} />
      case UI_TYPE.BULLETED_LIST:
        return formItem.requestValues ? <BulletedList values={formItem.requestValues} /> : ""
      default:
        return JSON.stringify(formItem)
    }
  }

  return (<Box sx={{display:'flex', flexDirection:'column'}}>
      <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', marginBottom:'20px'}}>
        <Box sx={{flexGrow: 1, paddingLeft:'20px', paddingRight:'20px', paddingTop:'0px'}}>
          <Typography variant="h4" sx={{ fontWeight: '600' }}>{review.form.name || ""}</Typography>
        </Box>
        {/* <DropDownMenu accountId={accountId} user={user} runDocId={run.docId} /> */}
      </Box>
      <Box sx={{display:'flex', flexDirection:'column', marginBottom:'20px'}}>
        <Typography variant="subtitle2" sx={{color:'#888', fontSize:'12px', paddingLeft:'20px', textAlign:'start'}}>{(review.createdAt) ? ((new Date(review.createdAt.seconds * 1000)).toDateString() + " " + (new Date(review.createdAt.seconds * 1000)).toLocaleTimeString()) : ""}</Typography>
      </Box>
      <Box sx={{display:'flex', flexDirection:'column', gap:'20px', marginTop:'14px', marginBottom:'10px', px:'20px', py:'40px', border:'1px solid #ccc', borderRadius:'8px', background:'#E7E5DF'}}>
        {formItems.map((formItem) =>
          <Box key={formItem.field}>{mapUiTypes(formItem)}</Box>
        )}
        {alreadyResponded && <ReactedSticker review={review} />}
          <Button variant="contained" color="approve" sx={{alignSelf:'end', borderRadius: '8px'}} disabled={alreadyResponded || !allRequiredFieldsResponded || submitTrigger} onClick={()=>{if (!previewOnly) setSubmitTrigger(true);}} startIcon={(submitTrigger && !alreadyResponded) ? <CircularProgress color="approve" size={24} /> : <DoneIcon />}>Submit</Button>
      </Box>
    </Box>
  )
};

export default React.memo(ReviewForm);

const _HeaderComponent = ({text}) => {
  return <Typography variant='h6'>{text}</Typography>
}
const HeaderComponent = React.memo(_HeaderComponent)

const _StaticTextComponent = ({text}) => {
  return <Typography sx={{whiteSpace:'pre-line'}}>{text}</Typography>
}
const StaticTextComponent = React.memo(_StaticTextComponent)

const TextComponent = ({formItem, currentValue, onChange, disabled, startEditorOpen = false}) => {
  const [editing, setEditing] = useState(startEditorOpen);

  return (
    <Box sx={{display:'flex', flexDirection:'column', gap: '8px', width:'100%'}}>
        {(formItem.label) && (
          <Box sx={{display:'flex', flexDirection:'row', gap: '8px'}}>
            <Box sx={{paddingLeft:'10px', typography: 'caption'}}>{formItem.label}</Box>
          </Box>)}
          <Box sx={{display:'flex', flexWrap:'wrap', width:(!editing || disabled) ? 'fit-content' : 'auto', background:'#fff', borderRadius:'4px', gap: '0px'}}>
            {(currentValue != null) && (!editing || disabled) && <Box sx={{
              width:'fit-content',
              maxHeight: '18em',
              overflowY: 'auto',
              padding: '4px',
              '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 8,},'::-webkit-scrollbar': {width: '6px'},
            }}>
              <Typography sx={{padding:'10px', whiteSpace:'pre-line', lineHeight:'1.5em'}}>{(currentValue || <>&nbsp;</>)}</Typography>
            </Box>}
            {editing && !disabled && <TextField
              fullWidth
              multiline
              maxRows={12}
              sx={{whiteSpace:'pre-line'}}
              value={currentValue || ""}
              onChange={(event) => {
                onChange(formItem, event.target.value);
              }}
            />}
            {formItem.settings.editable && !disabled && <Box sx={{display:'flex', width:'fit-content', px:'10px', py:'6px'}}>
              {!editing && <Button variant="outlined" color="primary" onClick={()=>setEditing(true)} startIcon={<EditIcon />}>Edit</Button>}
              {editing && <Button variant="contained" color="primary" onClick={()=>setEditing(false)} startIcon={<DoneIcon />}>Done</Button>}
            </Box>}
        </Box>
      </Box>
  )
}

const _FileLink = ({values}) => {
  // return <IconButton component={Link} href={values.url} target="_blank"><CloudDownloadIcon />{values.label}</IconButton>
  return <Box sx={{ display:'flex', width:'fit-content', alignItems:'center', gap:'6px', color: '#71717a', background:'#FFF', border:'1px solid #ccc', borderRadius:'8px', px:'12px', py:'4px' }}><CloudDownloadIcon fontSize='12px' /><Typography variant="subtitle2" component={Link} href={values.url} target="_blank">{values.label}</Typography></Box>
}
const FileLink = React.memo(_FileLink)

const UrlLink = React.memo(({values}) => {
  return <Box sx={{ display:'flex', width:'fit-content', alignItems:'center', gap:'6px', color: '#71717a', background:'#FFF', border:'1px solid #ccc', borderRadius:'8px', px:'12px', py:'4px' }}><OpenInNewIcon fontSize='12px' /><Typography variant="subtitle2" component={Link} href={values.url} target="_blank">{values.label}</Typography></Box>
})

const _CardGrid = ({values}) => {
  return (      <Grid container spacing={4}>
    {values.map((card, i) =>
      <Grid key={i} xs={12} sm={12} md={6} lg={4}>
        <Paper sx={{ padding: '20px', minHeight: '120px', border:'1px solid #ccc', borderRadius:'8px', overflowX: 'hidden' }}>
          {card.header && <Typography sx={{ marginBottom: '10px', fontSize:'18px', fontWeight: '500' }}>{card.header}</Typography>}
          {card.subtitle && <Typography variant='body2' sx={{ marginBottom: '22px', lineHeight: '18px' }}>{card.subtitle}</Typography>}
          <Box sx={{display: 'flex', flexDirection:'column', gap: '4px'}}>
            {card.stats && card.stats.map((stat,i) => <Box key={i} sx={{display: 'flex', gap: '10px'}}>
              <Typography variant='body2' sx={{ fontWeight: '600' }}>{stat.key}</Typography>
              <Typography variant='body2' sx={{  }}>{stat.value}</Typography>
            </Box>)}
          </Box>
        </Paper>
      </Grid>
    )}
  </Grid>)
}
const CardGrid = React.memo(_CardGrid)

const _ImageGrid = ({values}) => {
  return (      <Grid container spacing={4}>
    {values.map((card, i) =>
      <Grid key={i} xs={12} sm={6} md={6} lg={4}>
        <Paper sx={{ padding: '20px', minHeight: '120px', border:'1px solid #ccc', borderRadius:'8px', overflowX: 'hidden' }}>
          {card.label && <Typography variant='body2' sx={{ marginBottom: '22px', lineHeight: '18px' }}>{card.label}</Typography>}
          {card.url && <a href={card.url} target="_blank"><img
            src={`${card.url}`}
            width='100%'
            alt={card.label}
            loading="lazy"
          /></a>}
          <Typography variant='subtitle2' sx={{ color:'#aaa' }}>Click for full size</Typography>
        </Paper>
      </Grid>
    )}
  </Grid>)
}
const ImageGrid = React.memo(_ImageGrid)

const _RatingBar = ({formItem, currentValue, onChange, disabled}) => {
  return (
    <Rating name={formItem.field} value={currentValue || 0} disabled={disabled} readOnly={!formItem.settings.editable} precision={0.5} max={formItem.settings.max} onChange={(event, newValue) => { onChange(formItem, newValue); }} />
  )
}
const RatingBar = React.memo(_RatingBar)

const _BulletedList = ({values}) => {
  return (
    <List sx={{ listStyleType: 'disc', pl:4 }}>
      {values.map((val, i) => <ListItem key={i} sx={{ display: 'list-item' }}>{val}</ListItem>)}
    </List>
  )
}
const BulletedList = React.memo(_BulletedList)

const _ButtonSelect = ({formItem, currentValue, onChange, disabled}) => {
  const isSmallScreenOrLess = useMediaQuery(useTheme().breakpoints.down("md"))
  const isXSmallScreenOrLess = useMediaQuery(useTheme().breakpoints.down("sm"))
  // if introducing default values, change useEffect[formItems]! NOTE: here `requestValues` are not API-preset inputs/selections but choices
  const choices = (formItem.values || formItem.requestValues)
  if (!choices) return ""

  return (
    <ToggleButtonGroup
      value={currentValue || ""}
      exclusive={!formItem.settings.multiSelect}
      onChange={(event, newValue) => onChange(formItem, newValue == null ? null : (Array.isArray(newValue) ? (newValue.length ? newValue : null) : newValue))}
      disabled={disabled}
      orientation={isSmallScreenOrLess ? ((isXSmallScreenOrLess && choices.length > 2) ? 'vertical' : 'horizontal') : 'horizontal'}
    >
      {/* requestValues if coming per API with review. values if statically set */}
      {choices.map(val => <ToggleButton key={val.id} color={(val.style && ['accept', 'reject'].includes(val.style)) ? val.style : 'secondary'} value={val.id}>{val.label}</ToggleButton>)}
    </ToggleButtonGroup>
  )
}
const ButtonSelect = React.memo(_ButtonSelect)

const DropDownSelect = React.memo(({formItem, currentValue, onChange, disabled}) => {
  // if introducing default values, change useEffect[formItems]! NOTE: here `requestValues` are not API-preset inputs/selections but choices
  const choices = (formItem.values || formItem.requestValues)
  if (!choices) return ""

  return (
    <FormControl sx={{ m: 1, minWidth: 180 }} disabled={disabled}>
        <Select
          id="dropdown-select"
          value={currentValue || ""}
          onChange={(event) => onChange(formItem, event.target.value.length ? event.target.value : null)}
          autoWidth
        >
          <MenuItem value="">-</MenuItem>
          {choices.map(val => 
            <MenuItem key={val.id} value={val.id}>{val.label}</MenuItem>
          )}
        </Select>
      </FormControl>
  )
})

const Checkboxes = React.memo(({formItem, currentValue, onChange, disabled}) => {
  // if introducing default values, change useEffect[formItems]! NOTE: here `requestValues` are not API-preset inputs/selections but choices
  const choices = (formItem.values || formItem.requestValues)
  if (!choices) return ""

  const handleChange = (event) => {
    let changeArr = currentValue ? [...currentValue] : [];
    if (event.target.checked) {
      if (!changeArr.includes(event.target.name)) {
        changeArr.push(event.target.name);
      }
    } else {
      changeArr = changeArr.filter(val => val !== event.target.name);
    }
    onChange(formItem, changeArr.length ? changeArr : null);
  };

  return (
    <FormControl
        sx={{ m: 3 }}
        variant="standard"
        disabled={disabled}
      >
        <FormGroup>
        {choices.map(val => 
        {console.log("Checkboxes val " + (currentValue ? currentValue.includes(val.id) : "none"), val)
          return <FormControlLabel
            key={val.id}
            control={
              <Checkbox checked={currentValue ? currentValue.includes(val.id) : false} onChange={handleChange} name={val.id} color={(val.style && ['accept', 'reject'].includes(val.style)) ? val.style : 'secondary'} />
            }
            label={val.label}
          />}
        )}
        </FormGroup>
      </FormControl>
  )
})

const ReactedSticker = ({review}) => {
  let bg = '#e5b603';
  let text = 'Response submitted';
  return <Tooltip title={(review.userEmailResponded ? `by ${review.userEmailResponded} ` : " ") + (review.respondedAt ? ((new Date(review.respondedAt.seconds * 1000)).toDateString() + " " + (new Date(review.respondedAt.seconds * 1000)).toLocaleTimeString()) : "")}><Box sx={{alignSelf:'end'}}><Typography variant='subtitle1' sx={{ fontSize:'12px', background:bg, color: '#FFF', borderRadius:'4px', paddingLeft: '6px', paddingRight: '6px'}}>{text}</Typography></Box></Tooltip>
}