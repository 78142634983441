import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import { getPerformance } from "firebase/performance";

const FirebaseManager = (function() {
  function SingletonClass() {
    this.init = () => {};
  }

  let instance;

  return {
    getInstance: function() {
      if (instance == null) {
        instance = new SingletonClass();
        const firebaseConfig = {
          apiKey: "AIzaSyA-_j-VTHjb0cqDZPQdcjUuLpu8NLKQyiI",
          authDomain: "auth.gotohuman.com",
          projectId: "gotohuman",
          storageBucket: "gotohuman.appspot.com",
          messagingSenderId: "396485546793",
          appId: "1:396485546793:web:3e7395fcff5bc9b3e2dd38",
          measurementId: "G-NHFQ1NPXSG"
        };
        const app = initializeApp(firebaseConfig);
        const perf = getPerformance(app);
        const analytics = getAnalytics();

        instance.constructor = null;
      }
      return instance;
    },
  };
})();

export default FirebaseManager;
