import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, ListItemButton, Divider, TextField, Button, useMediaQuery, useTheme, Tooltip, Popover, Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, DialogActions, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Data from './Data';
import ReviewForm from './ReviewForm';
import { REVIEW_STATE } from './ReviewStates';
import { Timestamp } from 'firebase/firestore';
import { AccountContext, UserContext } from './AccountFrame';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import prism from 'react-syntax-highlighter/dist/esm/styles/prism/prism';
SyntaxHighlighter.registerLanguage('json', json);
import { UI_TYPE, UI_TYPES_SHOW_DATA, UI_TYPES_SHOW_STATIC, UI_TYPES_COLLECT_INPUT } from './UiTypes';
import { TEMPLATES } from './FormTemplates';

const FormEditor = ({formToEdit = null}) => {
  const accountCtx = useContext(AccountContext);
  const accountId = accountCtx?.account?.id
  const navigate = useNavigate();

  const [key, setKey] = useState();
  const [form, setForm] = useState(formToEdit || {name: "My Review Form"});
  const [changeId, setChangeId] = useState();
  const [savedChangeId, setSavedChangeId] = useState();
  const pendingChanges = changeId !== savedChangeId
  const [saveTrigger, setSaveTrigger] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [openComponentAdd, setOpenComponentAdd] = useState();
  const [dummyReviewRequest, setDummyReviewRequest] = useState();
  const [dummyFullReview, setDummyFullReview] = useState();
  const [webhookPayload, setWebhookPayload] = useState();
  const [previewResponseValues, setPreviewResponseValues] = useState({});
  const formComplete = form && form.name && (form.name.trim().length > 0) && form.webhook && (form.webhook.trim().length > 0 && form.fields && (Object.keys(form.fields).length > 0))
  // const dummyFullReview = (form && dummyReviewRequest) ? {...dummyReviewRequest, ...{ state: REVIEW_STATE.PENDING, createdAt: Timestamp.now(), form: form }} : null
  const formFields = form?.fields
  const formFieldOrder = form?.fieldOrder
  const formId = form?.docId
  const formName = form?.name
  const formDummyRequest = form?.dummyRequest

  useEffect(() => {
    if (!accountId) return;
    setKey(null);
    const subscription = Data.getInstance().fetchApiKey(`Accounts/${accountId}`)
        .subscribe((key) => setKey(key));
    return () => {
      subscription.unsubscribe();
    };
  }, [accountId]);

  useEffect(() => {
    if (!saveTrigger) return () => { };
    setSuccessMsg()
    let subscription;
    if (formId) {
      subscription = Data.getInstance().updateForm(accountId, formId, form)
      .subscribe((x) => {
        setSaveTrigger(false);
        setSavedChangeId(changeId)
        setSuccessMsg('Updated your form')
        },
      );
    } else {
      if (accountId) {
        subscription = Data.getInstance().addForm(accountId, form)
          .subscribe((newId) => {
            setSaveTrigger(false);
            setSavedChangeId(changeId)
            setForm(prev => {return {...prev, docId: newId}})
            setSuccessMsg('Created ' + newId)
            },
          );
      } else {
        //save locally
        try {
          const jsonString = JSON.stringify(form);
          localStorage.setItem(Data.LOCAL_STORAGE_FORM_KEY, jsonString);
          navigate(`/signin?creatingForm=true`);
        } catch (error) {
          console.error("Error saving form to local storage", error);
        }
      }
    }

    return () => {
      if (subscription)
        subscription.unsubscribe();
    };
  }, [accountId, saveTrigger]);
      
  useEffect(() => {
    const generateDummyReview = (formId, formFields, formDummyRequest) => {
      const dummyReview = {
        formId: formId || "123456abcdef",
        ...{fields: {}},
      };
    
      for (const [key, field] of Object.entries(formFields || {})) {
        const gridItemSample = {
          header: "Lirum Larum",
          subtitle: "My subtitle",
          stats: [
            {key: "Item1", value: "Value 1"},
            {key: "Item2", value: "Value 2"},
            {key: "Item3", value: "Value 3"}
          ]
        }
          switch (field.uiType) {
            case 'header':
              dummyReview.fields[key] = formDummyRequest?.fields[key] || "Lirum Larum Ipsum Header Text";
              break;
            case 'text':
              dummyReview.fields[key] = formDummyRequest?.fields[key] || "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.";
              break;
            case 'fileLink':
              dummyReview.fields[key] = formDummyRequest?.fields[key] || {
                url: "https://www.sbs.ox.ac.uk/sites/default/files/2019-01/cv-template.pdf",
                label: "Open This File"
              };
              break;
            case 'urlLink':
              dummyReview.fields[key] = formDummyRequest?.fields[key] || {
                url: "https://openai.com/news/",
                label: "Go to link"
              };
              break;
            case 'rating':
              dummyReview.fields[key] = formDummyRequest?.fields[key] || 4.5;
              break;
            case 'imageGrid':
              dummyReview.fields[key] = formDummyRequest?.fields[key] || [1,2,3].map(i=>{return {
                label: 'Image '+i, url: 'https://cdn.prod.website-files.com/6605a2979ff17b2cd1939cd4/66fe9dc570ff5555b44f4fae_landscape-sample-img.jpg'
              }});
              break;
            case 'cardGrid':
              dummyReview.fields[key] = formDummyRequest?.fields[key] || [
                gridItemSample,
                gridItemSample,
                gridItemSample
              ];
              break;
            case 'bulletedList':
              dummyReview.fields[key] = formDummyRequest?.fields[key] || [
                "Lorem ipsum dolor sit amet. Ut enim consectetur qui quia quidem ab maxime",
                "Eum eveniet internos ea consequuntur nihil id libero illo eum repudiandae labore",
                "Et sint nemo est quaerat necessitatibus et magnam aperiam"
              ];
              break;
            case 'checkboxes':
              if (!field.values) {
                dummyReview.fields[key] = formDummyRequest?.fields[key] || [
                  {
                    id: 'box1', label: 'Checkbox 1'
                  },
                  {
                    id: 'box2', label: 'Checkbox 2'
                  }
                ]
              }
              break;
            case 'dropdown':
              if (!field.values) {
                dummyReview.fields[key] = formDummyRequest?.fields[key] || [1,2,3,4,5,6,7,8].map(i => {return {id: 'option'+i, label: 'Option '+i}})
              }
              break;
            case 'buttonSelect':
              if (!field.values) {
                dummyReview.fields[key] = formDummyRequest?.fields[key] || [
                  {
                    id: 'choice1', label: 'Choice 1'
                  },
                  {
                    id: 'choice2', label: 'Choice 2'
                  }
                ]
              }
              break;
            /* case 'staticText':
              dummyReview.fields[key] = field.value;
              break; */
            /* case 'cardSelect':
              break; */
            /* default:
              dummyReview.fields[key] = null; // Default case for unknown uiTypes */
          }
      }
    
      console.log("generated " + JSON.stringify(dummyReview))
      return dummyReview;
    }
    const dummyReq = generateDummyReview(formId, formFields, formDummyRequest);
    setDummyReviewRequest(dummyReq)
    setDummyFullReview((form && dummyReq) ? {...dummyReq, ...{ state: REVIEW_STATE.PENDING, createdAt: Timestamp.now(), form: form }} : null)
  }, [formId, formName, formFields, formDummyRequest]);  
    
  useEffect(() => {
    setWebhookPayload({
      accountId: accountId || "123456abcdef",
      reviewId: "123456abcdef",
      formId: formId || "123456abcdef",
      formName: formName ? formName : "My Form",
      respondingUser: "jess@acme.org",
      respondedAt: "2024-10-05T14:48:00.000Z",
      responseValues: previewResponseValues
    })
  }, [accountId, formId, formName, previewResponseValues]);

  const onPreviewResponseChanged = useCallback((values) => {
    setPreviewResponseValues(values);
  }, [])

  const onCompAddClick = useCallback((uiType) => {
    setOpenComponentAdd([uiType, null]);
  }, [])

  const onCompAdded = useCallback((compInfoToAdd) => {
    updateForm(prev => {
      const change = {...prev, ...{fieldOrder: [...(prev?.fieldOrder || []), ...(!prev||!prev.fields||!prev.fields[compInfoToAdd.key] ? [compInfoToAdd.key] : [])], fields: {...(prev?.fields || {}), ...{[compInfoToAdd.key]: compInfoToAdd.object}}}}
      console.log("PREV ", prev)
      console.log("NEW ", change)
      return change;
    })
  }, [])

  const onCompEditClick = useCallback((fieldId, field) => {
    setOpenComponentAdd([field.uiType, {key: fieldId, object: field}])
  }, [])

  const onCompMoveUp = useCallback((fieldId) => {
    onMove(fieldId, true)
  }, [])

  const onCompMoveDown = useCallback((fieldId) => {
    onMove(fieldId, false)
  }, [])

  const onDeleteField = useCallback((fieldId) => {
    updateForm(prev => {
      const newfields = {...prev.fields};
      delete newfields[fieldId]
      const change = {...prev, ...{fieldOrder: prev.fieldOrder.filter(el=>el!==fieldId), fields: newfields}}
      console.log("PREV ", prev)
      console.log("NEW ", change)
      return change;
    })
  }, [])

  const onMove = useCallback((fieldId, up) => {
    updateForm(prev => {
      const currentIndex = prev.fieldOrder.findIndex(el => el === fieldId)
      const change = {...prev, ...{fieldOrder: prev.fieldOrder.filter(el=>el!==fieldId).toSpliced(currentIndex+(up ? -1 : 1),0,fieldId)}}
      console.log("PREV ", prev)
      console.log("NEW ", change)
      return change;
    })
  }, [])

  const onTemplateSelected = useCallback((form) => {
    updateForm(() => form)
  }, [])

  const onChangeFormName = useCallback((name) => {
    updateForm(form => {return {...(form && form), name: name};})
  }, [])

  const onChangeFormWebhook = useCallback((webhook) => {
    updateForm(form => {return {...form, webhook: webhook};})
  }, [])

  const updateForm = (updateFn) => {
    setForm((form) => {
      const updatedForm = updateFn(form);
      return updatedForm;
    });
    setSavedChangeId(Date.now()+"");
  };

  return (<><Box sx={{
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%', maxWidth: '1160px', px: {xs: '0px', sm: '24px'}, paddingTop: {xs: '14px', lg: '40px'}, paddingBottom: '60px', }}>
      <Box sx={{ display: 'flex', justifyContent:'space-between', mb:'20px' }}>
        <Typography variant="h4" sx={{ fontWeight: '600' }}>{formId ? 'Update Form':'Create New Form'}</Typography>
        {formId && accountId && <DropDownMenu accountId={accountId} formId={formId} isDeleted={form.deleted} />}
      </Box>
      {!formId && <Typography variant="subtitle2" sx={{ my: '10px', color:'#666', px:'0px' }}>Create a form to present to human supervisors when your AI app needs input or approval. You can dynamically inject the right data into components with every request for review you send.<br/>Perfect to review AI-generated content or agent tool calls, and to see additional data as context for decision-making.</Typography>}
      {!formId && <Box sx={{ display: 'flex', flexDirection: 'column', my:'18px', p:'12px', borderRadius:'8px', background:'#fff' }}>
        <Typography variant="subtitle2" sx={{ marginBottom: '10px', color:'#666', px:'16px' }}>Start with a template</Typography>
        <Box sx={{ display: 'flex', gap:'8px', flexWrap:'wrap' }}>
          {TEMPLATES.map(template => <Button key={template.name} variant="outlined" color='rose' sx={{borderRadius: '8px'}} disabled={saveTrigger} onClick={()=> onTemplateSelected(template.form)}>{template.name}</Button>)}
        </Box>
      </Box>}
        <Box display={'flex'} gap={'8px'} flexWrap={'wrap'} sx={{my:'14px'}}>
          <Typography variant="subtitle2" sx={{ color: '#71717a', textWrap:'nowrap' }} component={Link} href="#preview">↓ Preview form</Typography>
          <Typography variant="subtitle2" sx={{ color: '#71717a', textWrap:'nowrap' }} component={Link} href="#api-request">↓ API request</Typography>
          <Typography variant="subtitle2" sx={{ color: '#71717a', textWrap:'nowrap' }} component={Link} href="#webhook">↓ Webhook</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          mt: '16px',
        }}>
          <Typography id='create' variant="h6" sx={{ marginBottom: '20px', mt: '0px', background:'#114B5F', color:'#fff', fontWeight: '600', borderRadius:'4px', px:'20px', py:'6px' }}>{formId ? 'Update' : 'Create'} Form</Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            background:'#fff',
            borderRadius:'8px',
            p:'12px'
          }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '16px' }}>
              <Typography variant="h6" sx={{ fontWeight: '600' }}>Name</Typography>
              <TextField id="input-form-name" variant="standard" sx={{mx:'12px',fontSize:'14px'}} value={form?.name || ""} onChange={(ev) => onChangeFormName(ev.target.value)} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap', marginBottom: '16px' }}>
              <Typography variant="h6" sx={{ fontWeight: '600' }}>Webhook for submissions</Typography>
              <TextField id="input-form-webhook" variant="standard" sx={{mx:'12px',fontSize:'14px', flex:1, minWidth:'200px'}} value={form?.webhook || ""} onChange={(ev) => onChangeFormWebhook(ev.target.value)} />
            </Box>
            <Typography variant="h6" sx={{ marginBottom: '16px', fontWeight: '600' }}>Form components</Typography>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: '32px',}}>
              <FormComponentList onCompAddClick={onCompAddClick} />
              <FormItemList formFieldOrder={formFieldOrder} formFields={formFields} onEdit={onCompEditClick} onDelete={onDeleteField} onMoveDown={onCompMoveDown} onMoveUp={onCompMoveUp} />
            </Box>
            <Button variant="contained" color="accent" sx={{alignSelf:'end', borderRadius: '8px', marginTop:'10px'}} disabled={!formComplete || saveTrigger} onClick={()=>{setSaveTrigger(true);}} startIcon={(saveTrigger) ? <CircularProgress color="approve" size={24} /> : <DoneIcon />}>{formId ? 'Update' : 'Create'} Form</Button>
            {(successMsg || pendingChanges) && <Typography variant="caption" color={pendingChanges ? "error" : "secondary"} sx={{ alignSelf:'end',my: '6px' }}>{pendingChanges ? ('You have unsaved changes!'+(formComplete ? '' : ' (But missing name, webhook or fields)')) : successMsg}</Typography>}
          </Box>
          <Typography id='preview' variant="h6" sx={{ marginBottom: '12px', mt: '80px', background:'#114B5F', color:'#fff', fontWeight: '600', borderRadius:'4px', px:'20px', py:'6px' }}>Preview</Typography>
          <Typography variant="subtitle2" sx={{ color:'#999', marginBottom: '16px', px:'20px' }}>This is how the form will be shown to your supervising team for the review requests you send:</Typography>
          <Divider sx={{mb:'40px'}} />
          {dummyFullReview ? <ReviewForm review={dummyFullReview} previewOnly={true} onResponseValuesChanged={onPreviewResponseChanged} /> : <Box><Typography variant="body1" color="secondary" sx={{ p: '34px' }}>Preview will appear here</Typography></Box>}
          <Divider sx={{my:'40px'}} />
          {form && <Box sx={{display:'flex', flexDirection: {xs: 'column', md: 'row'}, gap: '0px', mt:'0px', px:'0px'}}>
            <Box id='api-request' sx={{display:'flex', flexDirection: 'column', flex:1, width:{xs: '100%', md: '50%'}, px:'12px', mt:'20px'}}>
              <Typography variant="h6" sx={{ marginBottom: '12px', background:'#114B5F', color:'#fff', fontWeight: '600', borderRadius:'4px', px:'20px', py:'6px' }}>API request</Typography>
              <Typography variant="subtitle2" sx={{ color:'#999', marginBottom: '0px', px:'20px' }}>Send a review request via API (SDKs soon):</Typography>
              {/* <Typography variant="subtitle2" sx={{ color: '#aaa', mb: '32px' }} component={Link} href="https://docs.gotohuman.com" target="_blank">Read our docs</Typography> */}
              <Box sx={{display:'flex', flexDirection: 'column', gap: '0px', background:'#fff', borderRadius:'8px', mt:'24px', px:'10px', py:'20px'}}>
              <Box display={'flex'} flexDirection={'column'} sx={{height:'60px', justifyContent:'space-around', marginBottom: '10px', overflowX:'auto', background:'#444', borderRadius:'4px', px:'12px', py:'2px',
                '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 2,},'::-webkit-scrollbar': {height: '8px'}
              }}>
                <Box display={'flex'} sx={{gap:'14px'}}>
                  <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600', textWrap:'nowrap' }}>HTTP POST</Typography>
                  <Typography sx={{color:'#f1f1f1', fontFamily:'monospace', fontWeight: '400' }}>https:/api.gotohuman.com/requestReview</Typography>
                </Box>
                <Box display={'flex'} sx={{gap:'14px', alignItems:'center'}}>
                  <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600', textWrap:'nowrap' }}>Headers[x-api-key]</Typography>
                  {accountId && <Typography variant="body1" sx={{color:'#f1f1f1', fontFamily:'monospace', fontWeight: '400', textWrap:'nowrap' }}>{key}</Typography>}
                  {(!accountId) && <Typography variant="caption" sx={{color:'#f1f1f1', fontWeight: '400', textWrap:'nowrap' }}><i>Create your form first</i></Typography>}
                </Box>
              </Box>
              <JsonBox obj={dummyReviewRequest} />
              </Box>
            </Box>
            <Box id='webhook' sx={{display:'flex', flexDirection: 'column', flex:1, width:{xs: '100%', md: '50%'}, px:'12px', mt:'20px'}}>
            <Typography variant="h6" sx={{ marginBottom: '12px', background:'#114B5F', color:'#fff', fontWeight: '600', borderRadius:'4px', px:'20px', py:'6px' }}>Webhook</Typography>
            <Typography variant="subtitle2" sx={{ color:'#999', marginBottom: '0px', px:'20px' }}>Webhook you'll receive when your users submit a review:</Typography>
              {/* <Typography variant="subtitle2" sx={{ color: '#aaa', mb: '32px' }} component={Link} href="https://docs.gotohuman.com" target="_blank">Read our docs</Typography> */}
              <Box sx={{display:'flex', flexDirection: 'column', gap: '0px', background:'#fff', borderRadius:'8px', mt:'24px', px:'10px', py:'20px'}}>
              <Box display={'flex'} flexDirection={'column'} sx={{height:'60px', justifyContent:'space-around', marginBottom: '10px', overflowX:'auto', overflowY:'hidden', background:'#444', borderRadius:'4px', px:'12px', py:'4px',
                '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 2,},'::-webkit-scrollbar': {height: '8px'}}}>
              {((form?.webhook || "").length == 0) && <Typography variant="body1" sx={{color:'#e1e1e1', fontWeight: '400', textAlign:'center', fontSize:'12px' }}>Enter your webhook URL above</Typography>}
                {((form?.webhook || "").length > 0) && <><Box display={'flex'} sx={{gap:'14px'}}>
                  <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600', textWrap:'nowrap' }}>HTTP POST</Typography>
                  <Typography variant="body1" sx={{color:'#f1f1f1', fontFamily:'monospace', fontWeight: '400' }}>{form?.webhook || ""}</Typography>
                </Box>
                <Box display={'flex'}>
                  <Typography variant="body1" sx={{color:'#e5b603', fontFamily:'monospace', fontWeight: '600' }}>&nbsp;</Typography>
                </Box></>}
              </Box>
              <JsonBox obj={webhookPayload} />
              </Box>
            </Box>
          </Box>}
        </Box>
    </Box>
  </Box>
  <ComponentAddDialog openComponentAdd={openComponentAdd != null} onClose={() => setOpenComponentAdd(null)} uiType={openComponentAdd && openComponentAdd[0]} compToEdit={openComponentAdd && openComponentAdd[1]} formFieldOrder={formFieldOrder} onConfirm={onCompAdded} />
  </>)
};

export default FormEditor;

const _JsonBox = ({obj}) => {
  const jsonString = obj ? JSON.stringify(obj, undefined, 4) : ""
  return <SyntaxHighlighter language="json" style={prism}>
    {jsonString}
  </SyntaxHighlighter>
}
const JsonBox = React.memo(_JsonBox)

const ComponentItem = ({uiType, onCompAddClick}) => {
  return <Button startIcon={<AddOutlinedIcon />} sx={{fontWeight: '600', border:'1px solid #ccc', borderRadius:'8px', width: '100%', maxWidth:'200px', mb:'6px', background:'#CFDBD5'}} onClick={()=> onCompAddClick(uiType)}>
    {uiType}
    </Button>
}

const _FormComponentList = ({onCompAddClick}) => {

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    width: {xs: '100%', md: '250px', lg: '460px'},
    // height:'100%',
    // minHeight: '400px',
    maxHeight:'540px',
    overflowY: 'auto',
    overflowX: 'hidden',
    pr: {xs: '10px', md:'16px', lg: '40px'},
    borderRight: {xs: '0px', md: '1px solid #ccc'},
    '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 4,},'::-webkit-scrollbar': {width: '4px'},
  }}>
    <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row', md: 'column'}, gap: '20px'}}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', flex:1}}>
        <Typography variant="subtitle2" sx={{ marginBottom: '10px' }}>Display static text</Typography>
        <Grid container columnSpacing={1}>
          {UI_TYPES_SHOW_STATIC.map((comp,i) => <Grid key={i} xs={6}sm={12}lg={6}><ComponentItem uiType={comp} onCompAddClick={onCompAddClick}/></Grid>)}
        </Grid>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', flex:1}}>
        <Typography variant="subtitle2" sx={{ marginBottom: '10px' }}>Collect user input</Typography>
        <Grid container columnSpacing={1}>
          {UI_TYPES_COLLECT_INPUT.map((comp,i) => <Grid key={i} xs={6}sm={12}lg={6}><ComponentItem uiType={comp} onCompAddClick={onCompAddClick}/></Grid>)}
        </Grid>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px', flex:1 }}>
        <Typography variant="subtitle2" sx={{ marginBottom: '10px' }}>Show dynamic data/drafts</Typography>
        <Grid container columnSpacing={1}>
          {UI_TYPES_SHOW_DATA.map((comp,i) => <Grid key={'dyn'+i} xs={6}sm={12} lg={6}><ComponentItem uiType={comp} onCompAddClick={onCompAddClick} /></Grid>)}
        </Grid>
      </Box>
    </Box>
  </Box>
}
const FormComponentList = React.memo(_FormComponentList)

const _FormItemList = ({formFieldOrder, formFields, onEdit, onDelete, onMoveUp, onMoveDown}) => {
  console.log("_FormItemList", formFieldOrder)
  return <Box sx={{display:'flex', flexDirection:'column', flex:1, gap:'10px'}}>
    <Typography variant="subtitle2" sx={{ marginBottom: '24px' }}>Added components</Typography>
    {(!formFieldOrder || formFieldOrder.length == 0) && <Box><Typography variant="body1" color="secondary" sx={{ p: '34px' }}>Added components will appear here</Typography></Box>}
    {formFields && formFieldOrder && formFieldOrder.map((fieldId, index) => <Box key={fieldId} sx={{display:'flex', alignItems:'center', justifyContent:'space-between', flexFlow:'wrap', background:'#eaeaea', px:'8px', py:'6px', gap:'8px', borderRadius:'8px'}}>
      <Box sx={{display:'flex', gap:'8px'}}>
        <Typography variant='subtitle2' sx={{ border:'1px solid #E26D5C', color:'#E26D5C', borderRadius:'3px', flexGrow:0, paddingLeft:'8px', paddingRight:'8px'}}>{formFields[fieldId].uiType}</Typography>
        {!UI_TYPES_SHOW_STATIC.includes(formFields[fieldId].uiType) && <Typography variant='subtitle2' sx={{fontFamily:'monospace', background:'#444', color:'#f1f1f1', flexGrow:0, borderRadius:'3px', paddingLeft:'8px', paddingRight:'8px'}}>{fieldId}</Typography>}
      </Box>
      <Box sx={{display:'flex', gap:'8px'}}>
        <IconButton size='small' onClick={() => onEdit(fieldId, formFields[fieldId])} ><EditIcon /> </IconButton>
        <IconButton size='small' onClick={() => onDelete(fieldId)} ><DeleteOutlineOutlinedIcon /> </IconButton>
        <IconButton size='small' disabled={index == 0} onClick={() => onMoveUp(fieldId)} ><ArrowUpwardOutlinedIcon /> </IconButton>
        <IconButton size='small' disabled={index == (formFieldOrder.length-1)} onClick={() => onMoveDown(fieldId)} ><ArrowDownwardOutlinedIcon /> </IconButton>
      </Box>
    </Box>)}
  </Box>
}
const FormItemList = React.memo(_FormItemList)

const ComponentAddDialog = ({openComponentAdd, onClose, uiType, compToEdit, formFieldOrder, onConfirm}) => {
  const [isValid, setIsValid] = useState(compToEdit != null)
  const [value, setValue] = useState(compToEdit)
  const allGood = !!value && isValid

  const onConfirmed = () => {
    if (!allGood) return;
    onConfirm(value);
    onClose();
  }

  const mapCompAdd = (uiType) => {
    switch (uiType) {
      case UI_TYPE.STATIC_TEXT:
      case UI_TYPE.STATIC_HEADER:
        return <EditStaticText uiType={uiType} compToEdit={compToEdit} onIsValidChange={setIsValid} onValueChange={setValue} />
      case UI_TYPE.BUTTON_SELECT:
      case UI_TYPE.CHECKBOXES:
      case UI_TYPE.DROPDOWN:
        return <EditOptionsSelect uiType={uiType} compToEdit={compToEdit} formFieldOrder={formFieldOrder} onIsValidChange={setIsValid} onValueChange={setValue} />
      case UI_TYPE.TEXT:
      case UI_TYPE.TEXT_INPUT:
        return <EditText uiType={uiType} compToEdit={compToEdit} formFieldOrder={formFieldOrder} onIsValidChange={setIsValid} onValueChange={setValue} editableIsOptional={uiType === UI_TYPE.TEXT} />
      case UI_TYPE.RATING:
        return <EditRating compToEdit={compToEdit} formFieldOrder={formFieldOrder} onIsValidChange={setIsValid} onValueChange={setValue} />
      case UI_TYPE.BULLETED_LIST:
      case UI_TYPE.FILE_LINK:
      case UI_TYPE.URL_LINK:
      case UI_TYPE.CARD_GRID:
      case UI_TYPE.IMAGE_GRID:
      case UI_TYPE.HEADER:
        return <EditIdOnlyType uiType={uiType} compToEdit={compToEdit} formFieldOrder={formFieldOrder} onIsValidChange={setIsValid} onValueChange={setValue} />
      default:
        return "";
    }
  }
  return <Dialog open={openComponentAdd} onClose={onClose}>
        <DialogTitle>{compToEdit ? 'Update' : 'Add'} {uiType}</DialogTitle>
        <DialogContent>
          {mapCompAdd(uiType)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Dismiss</Button>
          <Button 
          disabled={!allGood} 
          onClick={() => onConfirmed()}
          startIcon={<AddCircleOutlineIcon/>}
          >{compToEdit ? 'Update' : 'Save'} Component</Button>
        </DialogActions>
      </Dialog>
}

function isValidIdString(name) {
  // Regular expression for a valid JavaScript identifier
  const identifierRegex = /^[A-Za-z_$][A-Za-z0-9_$]*$/;

  // List of JavaScript reserved keywords
  const reservedKeywords = new Set([
    'abstract', 'await', 'boolean', 'break', 'byte', 'case', 'catch', 'char',
    'class', 'const', 'continue', 'debugger', 'default', 'delete', 'do', 'double',
    'else', 'enum', 'export', 'extends', 'false', 'final', 'finally', 'float',
    'for', 'function', 'goto', 'if', 'implements', 'import', 'in', 'instanceof',
    'int', 'interface', 'let', 'long', 'native', 'new', 'null', 'package',
    'private', 'protected', 'public', 'return', 'short', 'static', 'super',
    'switch', 'synchronized', 'this', 'throw', 'throws', 'transient', 'true',
    'try', 'typeof', 'var', 'void', 'volatile', 'while', 'with', 'yield'
  ]);

  // Check if the name matches the identifier regex and is not a reserved keyword
  return identifierRegex.test(name) && !reservedKeywords.has(name);
}

const EditStaticText = ({uiType, compToEdit = null, onIsValidChange, onValueChange}) => {
  const [text, setText] = useState(compToEdit?.object?.value)
  useEffect(() => {
    onValueChange({key: compToEdit?.key || Date.now()+"", object: {uiType: uiType, value: text?.trim()}})
    onIsValidChange(text && (text.trim().length > 0))
  }, [text]);

  return <Box sx={{display:'flex'}}>
  <TextField id="input-static-text" variant="standard" label="Enter text" multiline maxRows={8} fullWidth sx={{fontSize:'14px'}} value={text || ""} onChange={(ev) => setText(ev.target.value)} />
</Box>
}

const EditText = ({uiType, compToEdit = null, formFieldOrder, onIsValidChange, onValueChange, editableIsOptional}) => {
  const [id, setId] = useState((compToEdit?.key != null) ? compToEdit?.key : null)
  const [label, setLabel] = useState((compToEdit?.object?.label != null) ? compToEdit?.object?.label : null)
  const [editable, setEditable] = useState((compToEdit?.object?.settings?.editable != null) ? compToEdit?.object?.settings?.editable : true)
  const invalidId = id && id.length > 0 && !isValidIdString(id)

  useEffect(() => {
    onValueChange({key: id, object: {uiType: uiType, ...(label && (label.length > 0) && {label: label}), settings:{editable: editable}}})
    onIsValidChange(id && id.length > 0 && !invalidId && (compToEdit || !formFieldOrder || formFieldOrder.length == 0 || !formFieldOrder.includes(id)))
  }, [id, label, editable]);  

  return <Box sx={{display:'flex', flexDirection:'column', gap:'14px'}}>
  <Box sx={{display:'flex', flexDirection:'row', alignItems:'baseline', gap:'6px'}}>
    {!compToEdit && <TextField id="input-id" variant="standard" label="ID*" error={invalidId} helperText={invalidId ? "Invalid characters" : null} sx={{fontSize:'14px'}} value={id || ""} onChange={(ev) => setId(ev.target.value)} />}
    {compToEdit && <Typography sx={{my:'8px'}}>ID: {id || ""}</Typography>}
  </Box>
  <Box sx={{display:'flex', flexDirection:'row', alignItems:'baseline', gap:'6px'}}>
    <TextField id="input-label" variant="standard" label="Label" sx={{fontSize:'14px'}} value={label || ""} onChange={(ev) => setLabel(ev.target.value)} />
  </Box>
  {editableIsOptional && <FormControl>
    <FormLabel>Allow user to change value*</FormLabel>
    <RadioGroup
      row
      name="editable-radio-buttons-group"
      value={editable ? "yes" : "no"}
      onChange={(ev) => setEditable(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
      <FormControlLabel value="no" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>}
</Box>
}

const EditOptionsSelect = ({uiType, compToEdit = null, formFieldOrder, onIsValidChange, onValueChange}) => {
  const optionalMultiSelect = [UI_TYPE.BUTTON_SELECT].includes(uiType)
  const allowStyling = uiType === UI_TYPE.BUTTON_SELECT;
  const [id, setId] = useState((compToEdit?.key != null) ? compToEdit.key : "")
  const [required, setRequired] = useState((compToEdit?.object?.required != null) ? compToEdit?.object?.required : true)
  const [multiSelect, setMultiSelect] = useState((compToEdit?.object?.settings?.multiSelect != null) ? compToEdit?.object?.settings?.multiSelect : false)
  const [fixedChoices, setFixedChoices] = useState(!compToEdit || compToEdit.object.values)
  const [choice1, setChoice1] = useState((compToEdit?.object?.values && compToEdit?.object?.values.length > 0) ? compToEdit?.object?.values[0] : null)
  const [choice2, setChoice2] = useState((compToEdit?.object?.values && compToEdit?.object?.values.length > 1) ? compToEdit?.object?.values[1] : null)
  const [choice3, setChoice3] = useState((compToEdit?.object?.values && compToEdit?.object?.values.length > 2) ? compToEdit?.object?.values[2] : null)
  const [choice4, setChoice4] = useState((compToEdit?.object?.values && compToEdit?.object?.values.length > 3) ? compToEdit?.object?.values[3] : null)
  const invalidId = id && id.length > 0 && !isValidIdString(id)

  const isValidChoice = (choice) => {
    return choice && choice.id && choice.id.trim().length && choice.label && choice.label.trim().length
  }

  useEffect(() => {
    onValueChange({key: id, object: {uiType: uiType, required: required, ...(optionalMultiSelect && {settings:{multiSelect: multiSelect}}) , ...(fixedChoices && {values: [...(isValidChoice(choice1) ? [choice1] : []),...(isValidChoice(choice2) ? [choice2] : []),...(isValidChoice(choice3) ? [choice3] : []),...(isValidChoice(choice4) ? [choice4] : [])]})}})
    onIsValidChange(id && id.length > 0 && !invalidId && (compToEdit || !formFieldOrder || formFieldOrder.length == 0 || !formFieldOrder.includes(id)) && (!fixedChoices || (isValidChoice(choice1) || isValidChoice(choice2) || isValidChoice(choice3) || isValidChoice(choice4))))
  }, [id, required, multiSelect, fixedChoices, choice1, choice2, choice3, choice4]);  

  return <Box sx={{display:'flex', flexDirection:'column', fontSize:'12px', gap:'8px'}}>
  <Box sx={{display:'flex', flexDirection:'row', alignItems:'baseline', gap:'6px'}}>
    {!compToEdit && <TextField id="input-id" variant="standard" label="ID*" error={invalidId} helperText={invalidId ? "Invalid characters" : null} sx={{fontSize:'14px'}} value={id || ""} onChange={(ev) => setId(ev.target.value)} />}
    {compToEdit && <Typography sx={{my:'8px'}}>ID: {id || ""}</Typography>}
  </Box>
  <FormControl>
    <FormLabel>Required*</FormLabel>
    <RadioGroup
      row
      name="required-radio-buttons-group"
      value={required ? "yes" : "no"}
      onChange={(ev) => setRequired(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
      <FormControlLabel value="no" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>
  {optionalMultiSelect && <FormControl>
    <FormLabel>Allow multi-select*</FormLabel>
    <RadioGroup
      row
      name="multiSelect-radio-buttons-group"
      value={multiSelect ? "yes" : "no"}
      onChange={(ev) => setMultiSelect(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
      <FormControlLabel value="no" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>}
  <FormControl>
    <FormLabel>Fixed choices*</FormLabel>
    <RadioGroup
      row
      name="fixedChoices-radio-buttons-group"
      value={fixedChoices ? "yes" : "no"}
      onChange={(ev) => setFixedChoices(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
      <FormControlLabel value="no" control={<Radio />} label="No (sent with each review request)" />
    </RadioGroup>
  </FormControl>
  {fixedChoices && <><Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, alignItems:'baseline', gap:'6px'}}>
    <Typography variant='subtitle' color='secondary' sx={{px:'0px'}}>Choice&nbsp;1*</Typography>
    <TextField id="input-id1" variant="standard" label="ID*" sx={{fontSize:'14px'}} value={choice1?.id || ""} onChange={(ev) => setChoice1(prev => {return {...(prev && prev), id: ev.target.value}})} />
    <TextField id="input-label1" variant="standard" label="Label*" sx={{fontSize:'14px'}} value={choice1?.label || ""} onChange={(ev) => setChoice1(prev => {return {...(prev && prev), label: ev.target.value}})} />
    {allowStyling && <TextField id="input-style1" variant="standard" label="Style (accept | reject | '')" sx={{fontSize:'14px'}} value={choice1?.style || ""} onChange={(ev) => setChoice1(prev => {return {...(prev && prev), style: ev.target.value}})} />}
  </Box>
  <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, alignItems:'baseline', gap:'6px'}}>
    <Typography variant='subtitle' color='secondary' sx={{px:'0px'}}>Choice&nbsp;2&nbsp;</Typography>
    <TextField id="input-id2" variant="standard" label="ID" sx={{fontSize:'14px'}} value={choice2?.id || ""} onChange={(ev) => setChoice2(prev => {return {...(prev && prev), id: ev.target.value}})} />
    <TextField id="input-label2" variant="standard" label="Label" sx={{fontSize:'14px'}} value={choice2?.label || ""} onChange={(ev) => setChoice2(prev => {return {...(prev && prev), label: ev.target.value}})} />
    {allowStyling && <TextField id="input-style2" variant="standard" label="Style (accept | reject | '')" sx={{fontSize:'14px'}} value={choice2?.style || ""} onChange={(ev) => setChoice2(prev => {return {...(prev && prev), style: ev.target.value}})} />}
  </Box>
  <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, alignItems:'baseline', gap:'6px'}}>
    <Typography variant='subtitle' color='secondary' sx={{px:'0px'}}>Choice&nbsp;3&nbsp;</Typography>
    <TextField id="input-id3" variant="standard" label="ID" sx={{fontSize:'14px'}} value={choice3?.id || ""} onChange={(ev) => setChoice3(prev => {return {...(prev && prev), id: ev.target.value}})} />
    <TextField id="input-label3" variant="standard" label="Label" sx={{fontSize:'14px'}} value={choice3?.label || ""} onChange={(ev) => setChoice3(prev => {return {...(prev && prev), label: ev.target.value}})} />
    {allowStyling && <TextField id="input-style3" variant="standard" label="Style (accept | reject | '')" sx={{fontSize:'14px'}} value={choice3?.style || ""} onChange={(ev) => setChoice3(prev => {return {...(prev && prev), style: ev.target.value}})} />}
  </Box>
  <Box sx={{display:'flex', flexDirection:{xs:'column', sm:'row'}, alignItems:'baseline', gap:'6px'}}>
    <Typography variant='subtitle' color='secondary' sx={{px:'0px'}}>Choice&nbsp;4&nbsp;</Typography>
    <TextField id="input-id4" variant="standard" label="ID" sx={{fontSize:'14px'}} value={choice4?.id || ""} onChange={(ev) => setChoice4(prev => {return {...(prev && prev), id: ev.target.value}})} />
    <TextField id="input-label4" variant="standard" label="Label" sx={{fontSize:'14px'}} value={choice4?.label || ""} onChange={(ev) => setChoice4(prev => {return {...(prev && prev), label: ev.target.value}})} />
    {allowStyling && <TextField id="input-style4" variant="standard" label="Style (accept | reject | '')" sx={{fontSize:'14px'}} value={choice4?.style || ""} onChange={(ev) => setChoice4(prev => {return {...(prev && prev), style: ev.target.value}})} />}
  </Box></>}
</Box>
}

const EditRating = ({compToEdit = null, formFieldOrder, onIsValidChange, onValueChange}) => {
  const [id, setId] = useState((compToEdit?.key != null) ? compToEdit?.key : null)
  const [required, setRequired] = useState((compToEdit?.object?.required != null) ? compToEdit?.object?.required : true)
  const [editable, setEditable] = useState((compToEdit?.object?.settings?.editable != null) ? compToEdit?.object?.settings?.editable : false)
  const [max, setMax] = useState((compToEdit?.object?.settings?.max != null) ? compToEdit?.object?.settings?.max : 5)
  const invalidId = id && id.length > 0 && !isValidIdString(id)

  useEffect(() => {
    onValueChange({key: id, object: {uiType: UI_TYPE.RATING, required: required, settings:{max: max, editable: editable}}})
    onIsValidChange(id && id.length > 0 && !invalidId && (compToEdit || !formFieldOrder || formFieldOrder.length == 0 || !formFieldOrder.includes(id)) && max && max>0 && max <=20)
  }, [id, required, editable, max]);  

  return <Box sx={{display:'flex', flexDirection:'column', gap:'14px'}}>
  <Box sx={{display:'flex', flexDirection:'row', alignItems:'baseline', gap:'6px'}}>
    {!compToEdit && <TextField id="input-id" variant="standard" label="ID*" error={invalidId} helperText={invalidId ? "Invalid characters" : null} sx={{fontSize:'14px'}} value={id || ""} onChange={(ev) => setId(ev.target.value)} />}
    {compToEdit && <Typography sx={{my:'8px'}}>ID: {id || ""}</Typography>}
  </Box>
  <FormControl>
    <FormLabel>Required*</FormLabel>
    <RadioGroup
      row
      name="required-radio-buttons-group"
      value={required ? "yes" : "no"}
      onChange={(ev) => setRequired(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
      <FormControlLabel value="no" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>
  <FormControl>
    <FormLabel>Allow user to change value*</FormLabel>
    <RadioGroup
      row
      name="editable-radio-buttons-group"
      value={editable ? "yes" : "no"}
      onChange={(ev) => setEditable(ev.target.value === "yes")}
    >
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
      <FormControlLabel value="no" control={<Radio />} label="No" />
    </RadioGroup>
  </FormControl>
  <Box sx={{display:'flex', flexDirection:'row', alignItems:'baseline', gap:'6px'}}>
    <TextField id="input-max" variant="standard" label="Max stars*" sx={{fontSize:'14px'}} value={max} onChange={(ev) => setMax(isNaN(parseInt(ev.target.value)) ? null : parseInt(ev.target.value))} />
  </Box>
</Box>
}

const EditIdOnlyType = ({uiType, compToEdit = null, formFieldOrder, onIsValidChange, onValueChange}) => {
  const [id, setId] = useState((compToEdit?.key != null) ? compToEdit?.key : null)
  const invalidId = id && id.length > 0 && !isValidIdString(id)

  useEffect(() => {
    onValueChange({key: id, object: {uiType: uiType}})
    onIsValidChange(id && id.length > 0 && !invalidId && (compToEdit || !formFieldOrder || formFieldOrder.length == 0 || !formFieldOrder.includes(id)))
  }, [id]);  

  return <Box sx={{display:'flex', flexDirection:'column', gap:'14px'}}>
  <Box sx={{display:'flex', flexDirection:'row', alignItems:'baseline', gap:'6px'}}>
    {!compToEdit && <TextField id="input-id" variant="standard" label="ID*" error={invalidId} helperText={invalidId ? "Invalid characters" : null} sx={{fontSize:'14px'}} value={id || ""} onChange={(ev) => setId(ev.target.value)} />}
    {compToEdit && <Typography sx={{my:'8px'}}>ID: {id || ""}</Typography>}
  </Box>
</Box>
}


const _DropDownMenu = ({accountId, formId, isDeleted}) => {
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const [deleted, setDeleted] = useState(isDeleted);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    setDeleteTrigger(true);
  }
  
  useEffect(() => {
    if (!deleteTrigger) return () => { };
    Data.getInstance().markFormDeleted({accPath: `Accounts/${accountId}`, formId: formId} ).then(() => {
      setDeleteTrigger(false)
      setDeleted(true)
    }).catch((error) => {
      console.error("Couldn't delete form", error)
      setDeleteTrigger(false)
    });
  }, [deleteTrigger]);

  return (
    <Box sx={{padding:'0px'}}>
      {deleteTrigger && <Typography variant='subtitle'>Deleting...</Typography>}
      {deleted && <Typography variant='subtitle' color='error'>Deleted!</Typography>}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
          {!deleted && <MenuItem key={"delete"} onClick={handleDelete}>
            Delete
          </MenuItem>}
      </Menu>
    </Box>
  );
}
const DropDownMenu = React.memo(_DropDownMenu)