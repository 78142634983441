import React, {useState} from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import styles from './css/UserBar.module.css';
import { Avatar, AppBar, Toolbar, Tooltip, Popover, IconButton, Typography, Button, Box, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress } from '@mui/material';
import Fingerprint from '@mui/icons-material/Fingerprint';
import Logout from '@mui/icons-material/Logout';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import ArrowBack from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import Data from './Data';
import { useEffect } from 'react';

const AvatarSized = styled(Avatar)({
  margin: 'auto',
});

const UserBar = ({ user, currentAcc, noBottomBar }) => {
  // console.log('POPUSER ' + user.id);
  const navigate = useNavigate();
  const [showCopied, setShowCopied] = useState(false);
  const [anchorUserIdEl, setAnchorUserIdEl] = useState(null);
  const [askedForUserDelete, setAskedForUserDelete] = useState(false);
  const [userDeleteTrigger, setUserDeleteTrigger] = useState(false);
  const [userDeleteFailed, setUserDeleteFailed] = useState(false);
  const [askedForAccountDelete, setAskedForAccountDelete] = useState(false);
  const [accountDeleteTrigger, setAccountDeleteTrigger] = useState(false);

  const role = (!user || !user.access || !currentAcc) ? null : user.access[currentAcc.path];
  const isAdminForAccount = !!role && ['admin'].includes(role);

  useEffect(() => {
    if (!userDeleteTrigger) return;
    setUserDeleteFailed(false)
    Data.getInstance().deleteUser().then(() => {
      setUserDeleteTrigger(false)
      navigate("/", {replace: true})
    }).catch((error) => {
      console.error("Couldn't delete user", error)
      setUserDeleteFailed(true)
      setUserDeleteTrigger(false)
    });
  }, [userDeleteTrigger])

  useEffect(() => {
    if (!accountDeleteTrigger || !currentAcc) return;
    Data.getInstance().deleteDoc(currentAcc.path).then(() => {
      setAccountDeleteTrigger(false)
      handleClose();
    }).catch((error) => {
      console.error("Couldn't delete account", error)
      setAccountDeleteTrigger(false)
    });
  }, [accountDeleteTrigger])


  const handleClick = (event) => {
    setAnchorUserIdEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorUserIdEl(null);
    setShowCopied(false);
    setAskedForUserDelete(false);
    setAskedForAccountDelete(false);
    setUserDeleteFailed(false);
  };

  const copy = (text) => {
    navigator.clipboard.writeText(text);
    setShowCopied(true);
  }

  const open = Boolean(anchorUserIdEl);
  const id = open ? 'simple-popover' : undefined;

  let userDiv = '';
  let avatarDiv;
  const userEmail = user && user.email && user.email.length ? user.email : '';
  if (user) {
    avatarDiv = ((user.photoURL && user.photoURL.length) ? (<AvatarSized alt={user.name} src={user.photoURL} />) : (<AvatarSized>{user.name && user.name.length ? user.name.charAt(0) : (user.email && user.email.length ? user.email.charAt(0) : '')}</AvatarSized>));
  }
    userDiv = (
      user ? (<Box><Popover
        id={id}
        open={open}
        anchorEl={anchorUserIdEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{display: 'flex', flexDirection:'column', gap:'6px', alignItems:'start', padding:'10px'}}>
          <Typography variant='caption' sx={{}}>{userEmail}</Typography>
          <Tooltip title={user.id} placement='top'>
            <Button color="inherit" startIcon={<Fingerprint />} onClick={() => copy(user.id)}>{showCopied ? 'Copied \u2713' : 'Copy user ID'}</Button>
          </Tooltip>
          <Button color="inherit" startIcon={<Logout />} onClick={() => {handleClose(); navigate('/logout');}}>Logout</Button>
          {(!user.accounts || (user.accounts.length == 0)) && (askedForUserDelete ? <Box sx={{display: 'flex', flexDirection:'column'}}>Are you sure?<Box sx={{display: 'flex'}}><Button color="error" disabled={userDeleteTrigger} onClick={() => {setUserDeleteTrigger(true);}} startIcon={userDeleteTrigger ? <CircularProgress size={24} /> : <DeleteOutlineOutlinedIcon/>}>Delete</Button><Button disabled={userDeleteTrigger} onClick={() => setAskedForUserDelete(false)}>Cancel</Button></Box></Box> : <Button color="error" onClick={() => setAskedForUserDelete(true)}>Delete user</Button>)}
          {userDeleteFailed && <Typography color="error">Please log out and in and try again.</Typography>}
          {isAdminForAccount && (askedForAccountDelete ? <Box sx={{display: 'flex', flexDirection:'column'}}>Are you sure?<Box sx={{display: 'flex'}}><Button color="error" disabled={accountDeleteTrigger} onClick={() => {setAccountDeleteTrigger(true);}} startIcon={accountDeleteTrigger ? <CircularProgress size={24} /> : <DeleteOutlineOutlinedIcon/>}>Delete</Button><Button disabled={accountDeleteTrigger} onClick={() => setAskedForAccountDelete(false)}>Cancel</Button></Box></Box> : <Button color="error" onClick={() => setAskedForAccountDelete(true)}>Delete org</Button>)}
        </Box>
      </Popover>
        <Tooltip title={user.name && user.name.length ? (`${user.name} (${userEmail})`) : userEmail}>
          <IconButton onClick={handleClick}>{avatarDiv}</IconButton>
        </Tooltip>
      </Box>) : (
        <Box>
          <Button component={Link} to={`/signin`} sx={{  }}>Sign In</Button>
      </Box>
      )
    );
    // const title = <BigText variant="h6" >{currentApp ? currentApp.name : 'Appviewer'}</BigText>;
    /* let navigation = '';
    if (currentApp) {
      navigation = (
        <IconButtonSpaced edge="start" color="inherit" onClick={onNavigateBack}>
          <ArrowBack />
        </IconButtonSpaced>
      );
    } */
  // }
  
  return (
    <div sx={{
      flexGrow: 1,
      position: 'sticky',
      top: 0,
      zIndex: 100,
    }}>
      <AppBar sx={{alignItems:'center', backgroundColor:'#FBFBFB', borderBottom: noBottomBar ? '0' : '1px solid #eaeaea'}} position="static">
        <Toolbar sx={{width:'90%', maxWidth:'1224px', paddingLeft:'0px', paddingRight:'0px'}}>
          {/* {navigation} */}
          {/* {title} */}
          <Box className={styles.logoDiv} component={Link} to={`/`}>
            <img
            srcSet="/txtlogo166.png 1x, /txtlogo332.png 2x"
            sizes="(max-width: 166px) 100vw, 166px"
             src="/txtlogo166.png" className={styles.logo} />
            </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          </Box>
          {userDiv}
        </Toolbar>
      </AppBar>
    </div>
    /* <div className={styles.bar}>
      {navigation}
      {userDiv}
    </div> */
  );
};

export default UserBar;
