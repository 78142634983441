import React, {useState, useEffect, createContext} from 'react';
import {Outlet, useParams, useNavigate, NavLink} from 'react-router-dom';
import UserBar from './UserBar';
import {Box, Button, LinearProgress, List, ListItem, ListItemButton, Popover, ToggleButton, ToggleButtonGroup, Tooltip, Typography} from '@mui/material';
import Data from './Data';
import AccountCard from './AccountCard';
// import ConfirmEmail from "./ConfirmEmail";
import styled from '@emotion/styled';
import { catchError, map, tap } from 'rxjs/operators';

export const UserContext = createContext(); 
export const AccountContext = createContext();

const AccountFrame = ({user}) => {
  const navigate = useNavigate();
  const {accountId} = useParams();
  const [loading, setLoading] = useState(true);
  const [accSelected, setAccSelected] = useState();
  
  const role = (!user || !user.access || !accSelected) ? null : user.access[accSelected.path];
  const isDevOrAdmin = !!role && ['dev', 'admin'].includes(role);

  useEffect(() => {
    setLoading(true)
    const subscription = Data.getInstance().streamAccount(`Accounts/${accountId}`)
        .subscribe(
            (account) => {
              setLoading(false)
              setAccSelected(account);
            },
        );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId]);

  useEffect(() => {
    
    function readFromLocalStorage(key) {
      try {
        const jsonString = localStorage.getItem(key);
        if (jsonString) {
          return JSON.parse(jsonString);
        }
        return null;
      } catch (error) {
        console.error("Error reading from local storage", error);
        return null;
      }
    }
    const savedForm = readFromLocalStorage(Data.LOCAL_STORAGE_FORM_KEY)
    console.log("precreated form?", savedForm)
    if (!savedForm) {
      return () => { };
    }

    const subscription = Data.getInstance().addForm(account.id, savedForm)
            .pipe(
              tap(newId => console.log("Saved precreated form "+newId)),
              tap(x => localStorage.removeItem(Data.LOCAL_STORAGE_FORM_KEY)),
              catchError(() => console.log(err.message)),
            )
            .subscribe((newId) => {
              console.log("added precreated form " + newId);
            });
          return () => {
            subscription.unsubscribe();
          };
  }, []);

  const MyNavLink = React.forwardRef((props, ref) => (
    <NavLink
      ref={ref}
      {...props}
      // className={props.activeClassName}
      className={({ isActive }) => (isActive ? props.className + ' Mui-selected' : props.className)}
    />
  ));

  return (
    <div>
      <UserBar user={user} currentAcc={accSelected} noBottomBar={true} />
      <Box sx={{
        // padding: 50,
        // paddingTop: 20,
        // paddingBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 65px)',
        background: '#fafafa',
      }}>
        {loading &&  <LinearProgress color="secondary" />}
        {!loading && !accSelected && <Typography variant='subtitle2' sx={{padding:'40px'}}>Account not found</Typography>}
        {!loading && accSelected && <AccountContext.Provider value={{account: accSelected}}><UserContext.Provider value={{user: user}}>
            <Box  sx={{display:'flex', flexDirection: 'column', alignItems:'center', borderBottom:'1px solid #eaeaea'}}>
              <Box  sx={{display:'flex', flexDirection:{xs: 'column', lg: 'row'}, width:'90%', maxWidth:'1224px', gap:'10px', px: {xs:'0px', sm:'24px'}, pb: '6px'}}>
                <AccountCard account={accSelected} user={user} />
                {isDevOrAdmin && (
                  <List style={{ display: 'flex', flexDirection: 'row', padding: 0 }} sx={{fontFamily: 'Inter', fontSize: '16px', fontWeight: '500', color:'#3B7080'}}>
                    <ListItemButton color='primary'
                      component={MyNavLink}
                      to={`reviews`} 
                      sx={{width:'120px', maxWidth:'120px', justifyContent:'center'}}
                    >Reviews</ListItemButton>
                    <ListItemButton component={MyNavLink} to={`forms`} sx={{width:'120px', maxWidth:'120px', justifyContent:'center'}}>Forms</ListItemButton>
                  </List>
                )}
              </Box>
              {user && !user.emailVerified && <Box sx={{display:'flex', alignItems:'center', width:'100%', background:'#AF3800', px: {xs:'6px', sm:'24px'}, py:'6px'}}><Typography variant='caption' sx={{color:'#f1f1f1', textWrap:'nowrap'}}>Please verify your email address</Typography><Tooltip title="To re-send verification email, log out and in again"><Typography variant='caption' sx={{px:'6px', mx:'4px', color:'#f1f1f1', border:'1px solid #f1f1f1', borderRadius:'12px'}}>?</Typography></Tooltip></Box>}
            </Box>
            <Box sx={{
              flex: 1,
              background: '#f1f1f1',
              overflowY: 'auto',
              overflowX: 'hidden',
              '::-webkit-scrollbar-track': {background: "#00000000",},'::-webkit-scrollbar-thumb': {background: '#ccc',borderRadius: 8,},'::-webkit-scrollbar': {width: '8px'},
            }}>
              <Outlet />
            </Box>
          </UserContext.Provider></AccountContext.Provider>}
      </Box>
    </div>
  );
};

export default AccountFrame;