import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useNavigate, useLocation, useSearchParams, Link as RouterLink } from 'react-router-dom';
import { Button, FormControl, InputLabel, MenuItem, Select, useMediaQuery, useTheme } from '@mui/material';
import { Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, LinearProgress, Box, Paper, Link } from '@mui/material';
import { catchError, map } from 'rxjs/operators';
import Data from './Data';
import InboxItem from './InboxItem';
import { AccountContext, UserContext } from './AccountFrame';
import { RUN_STATE_GROUP } from './RunStates';

const Inbox = () => {
  const { account } = useContext(AccountContext);
  const accountId = account?.id
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [reviews, setReviews] = useState();
  const [shownReviewsCount, setShownReviewsCount] = useState(10);
  const [forms, setForms] = useState();
  const [currentTimeEvery10Secs, setPingPer10Secs] = useState(0);
  const filteredFormDocId = searchParams.get('form');
  const selectedForm = (filteredFormDocId && forms) ? forms.find((item) => item.docId == filteredFormDocId) : null
  const formCount = forms && forms.length;
  const role = (!user || !user.access || !account) ? null : user.access[account.path];
  const isDevOrAdmin = !!role && ['dev', 'admin'].includes(role);
  const isMediumScreenOrLess = useMediaQuery(useTheme().breakpoints.down("lg"))

  useEffect(() => {
    setLoading(true);
    setError(null);
    setReviews()
    if (filteredFormDocId && !selectedForm) return () => { }; //not loaded yet
    const subscription = Data.getInstance().streamReviews({ accPath: `Accounts/${accountId}`, ...(selectedForm && { formId: selectedForm.id }) }) //selectedForm not implemented right now //TODO: streamReviews
      .pipe(catchError(() => {
        setError('There was an error loading the pending reviews.');
        setLoading(false);
      }))
      .subscribe(
        (reviews) => {
          setLoading(false);
          setReviews([...reviews.sort((a,b)=>b.createdAt.toMillis() - a.createdAt.toMillis())]);
        },
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId, selectedForm]);

  useEffect(() => {
    if (!isDevOrAdmin) return () => { };
    // console.log("streamForms " + `Accounts/${account.id}`)
    const subscription = Data.getInstance().streamForms({ accPath: `Accounts/${accountId}`})
      .pipe(
        map(forms => forms.filter(form => !form.deleted)),
        catchError((err) => console.error('Error loading Forms.', err))
      )
      .subscribe(
        (forms) => setForms(forms),
      );

    return () => {
      subscription.unsubscribe();
    };
  }, [accountId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000);
      setPingPer10Secs(currentTime);
    }, 2000); // Update every 10 secs

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const handleListItemClick = useCallback((reviewClicked) => {
    console.log("handleListItemClick " + reviewClicked.docId)
    navigate(`/accounts/${accountId}/reviews/${reviewClicked.docId}`);
  }, [accountId]);

  function getTimeDiffValue(creationTime) {
    const timeDiff = currentTimeEvery10Secs - creationTime; // Calculate the difference in seconds

    if (timeDiff < 60) {
        // Less than 60 seconds ago
        return Math.floor(timeDiff / 10) * 10; // Output every 10 seconds
    } else if (timeDiff < 3600) {
        // Between 60 seconds and 60 minutes ago
        return Math.floor(timeDiff / 60) * 60; // Output every minute
    } else if (timeDiff < 172800) {
        // Between 60 minutes and 48 hours ago
        return Math.floor(timeDiff / 3600) * 3600; // Output every hour
    } else {
        // 48 hours ago or more
        return Math.floor(timeDiff / 43200) * 43200; // Output every 12 hours
    }
}

  return (<Box sx={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: {xs: '0px', sm: '24px'},
    paddingTop: {xs: '14px', lg: '40px'},
    paddingBottom: '60px',
    background: '#F1F1F1',
  }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%', maxWidth: '1160px', mb:'20px' }}>
      <Typography variant="h4" sx={{ marginBottom: '6px', fontWeight: '600' }}>Requests for Review</Typography>
      <Typography variant="subtitle2" sx={{ color: '#71717a' }}>You have {!reviews ? '...' : reviews.length} pending reviews</Typography>
    </Box>
    <Box sx={{
      display: 'flex',
      flexDirection: {xs: 'column', lg: 'row'},
      width:'90%',
      maxWidth:'1160px',
      gap: '40px',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        // width: {xs: '90%', lg: '70%'},
        // maxWidth: {xs: '800px', lg: '860px'},
        alignItems: 'center',
      }}>
        
        <Paper sx={{ width: '100%', background: '#FBFBFB', borderRadius: '10px', paddingTop: '6px', paddingBottom: '6px' }}>
          {loading && <LinearProgress color="secondary" />}
          {error && <Box sx={{ color: 'red', padding: '10px', textAlign: 'center' }}>{error}</Box>}
          {reviews && !reviews.length && <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center', px: '12px', py:'40px'}}>
            {!reviews.length && <Typography sx={{ color: '#aaa', textAlign: 'center', fontSize:{xs:'12px', sm:'14px'} }}>Review requests from your AI apps will appear here</Typography>}
            {(formCount == 0 && isDevOrAdmin) && <Typography sx={{ color: '#888', fontSize:{xs:'12px', sm:'14px'}, marginTop: '40px', px:'12px', py:'6px', textAlign: 'center', border:'1px solid #3B7080', borderRadius:'12px' }}>To get started, <Typography sx={{ color: '#71717a', textWrap:'nowrap', fontSize:'inherit' }} component={RouterLink} to={`/accounts/${accountId}/forms/new`}>create a form</Typography> for your reviews</Typography>}
            {(formCount > 0 && !reviews.length && isDevOrAdmin) && <Typography sx={{ color: '#888', fontSize:{xs:'12px', sm:'14px'}, marginTop: '40px', px:'12px', py:'6px', textAlign: 'center', border:'1px solid #3B7080', borderRadius:'12px' }}><Typography sx={{ color: '#71717a', textWrap:'nowrap', fontSize:'inherit' }} component={RouterLink} to={`/accounts/${accountId}/forms`}>Go to your form</Typography> to see the request you need to send from your AI app</Typography>}
          </Box>}
          {reviews && !!reviews.length && <List sx={{ paddingTop: '0px', paddingBottom: '0px', }}>
            {reviews.slice(0, shownReviewsCount).map((review, index) => {
              return <Box key={review.docId}><InboxItem review={review} inclDivider={index > 0} onItemClick={handleListItemClick} timePing={getTimeDiffValue(review.createdAt.seconds)} /></Box>
            })}
          </List>}
          {reviews && (reviews.length > shownReviewsCount) && <Box sx={{ display: 'flex', marginLeft: '30px', marginTop: '10px', marginBottom: '10px' }}>
            <Typography variant="subtitle2" sx={{ color: '#71717a' }}>+ {reviews.length == Data.REVIEW_LOAD_LIMIT}{reviews.length - shownReviewsCount} more</Typography>
            <Typography sx={{ display: 'inline', paddingLeft: '14px', fontSize: '14px', color: '#bbb', cursor: 'pointer' }} onClick={() => setShownReviewsCount(val => val + 10)}>Show more</Typography>
          </Box>}
        </Paper>
      </Box>
    </Box>
  </Box>)
};

export default Inbox;