import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemAvatar, Avatar, ListItemIcon, ListItemText, IconButton, Box, ListItemButton, Divider } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Data from './Data';
import Dots from './Dots';
import RunDate from './RunDate';
import { RUN_STATE_GROUP } from './RunStates';

const InboxItem = ({review, inclDivider, onItemClick, timePing}) => {
  const [input, setInput] = useState("");

  const previewString = (agentRun) => {
    //show action, otherwise last context item
    const preStr = (agentRun.action || ((agentRun.context && agentRun.context.length) ? agentRun.context[agentRun.context.length-1] : ""));
    const shortened = preStr.slice(0,180)
    const toReturn = (shortened.length < preStr.length) ? (shortened + "...") : shortened;
    console.log("previewString " + preStr.length + " shortened: " + shortened.length);
    return toReturn;
  }

  return (<>
  {inclDivider && <Divider component="li" />}
  <ListItemButton onClick={() => onItemClick(review)}>
  <ListItem>
    <ListItemAvatar>
      <Avatar sx={{bgcolor:'#CFDBD5'}}>{review.form.name.charAt(0)}</Avatar>
    </ListItemAvatar>
    <ListItemText sx={{paddingRight:'20px'}}
          primary={<Box sx={{display:'flex',flexDirection: {xs:'column', sm:'row'}, alignItems:'start', gap:{xs:'4px', sm:'20px'}, textWrap:'nowrap', overflow:'hidden'}}>{review.form.name}</Box>}
          
        />
        {/* {(run.state === RUN_STATE.RUNNING) && <Dots />} */}
    <RunDate timeStampDate={review.createdAt} pingForUpdate={timePing} />
    
  </ListItem>
  </ListItemButton>
  </>)
};

export default React.memo(InboxItem);